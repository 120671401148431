import { useMediaQuery, useTheme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { observer } from 'mobx-react';
import { AppTheme } from 'styles/theme';
import Flex from 'components/common/Flex';
import { AlllogoSvg, AppStoreWhiteSvg, GooglePlayWhiteSvg } from 'assets/icons';
import { DownTextSvg } from 'assets/images';
import { useHistory } from 'react-router-dom';
import LangSelect from 'components/LangSelect';
import { useAppLink } from "hooks/useAppLink";

interface DownloadPageProps {}

function DownloadPage(props: DownloadPageProps) {
  const styles = useStyles(props);
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const history = useHistory();

  const { toAppStore, toGooglePlay } = useAppLink();

  return (
    <Flex
      width={'100vw'}
      height={'100vh'}
      className={styles.download}
      bgcolor={mdDown ? '#000000' : '#18181B'}
      style={
        mdDown
          ? {
              backgroundImage: 'url("/images/down-back.png")'
            }
          : {}
      }
      column
      center
    >
      {!mdDown && (
        <>
          <Flex className={styles.downAfter}></Flex>
          <Flex className={styles.downForward}></Flex>
        </>
      )}

      <Flex
        style={{
          position: 'absolute',
          width: mdDown ? '80vw' : 1200,
          zIndex: 0
        }}
      >
        <DownTextSvg />
      </Flex>
      <Flex
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
          zIndex: 2
        }}
        column
        centerY
      >
        <Flex
          mt={10}
          mb={mdDown ? 10 : 0}
          width={mdDown ? '100vw' : 1200}
          style={{
            display: 'flex',
            justifyContent: mdDown ? 'center' : 'flex-start',
            alignItems: 'center',
            position: 'relative'
          }}
        >
          <AlllogoSvg style={{ cursor: 'pointer' }} onClick={() => history.push('/')} />
        </Flex>
        {mdDown ? (
          <Flex py={5} className={styles.text} fontSize={24} letterSpacing={'7.59px'}>
            DOWNLOAD NOW
          </Flex>
        ) : (
          <Flex py={10} className={styles.text} fontSize={50}>
            DOWNLOAD NOW
          </Flex>
        )}
        <Flex pb={10}>
          <Flex px={2} cursorPointer>
            <AppStoreWhiteSvg onClick={toAppStore} width={'100%'} height={mdDown ? 39 : 64} />
          </Flex>
          <Flex px={2} cursorPointer>
            <GooglePlayWhiteSvg onClick={toGooglePlay} width={'100%'} height={mdDown ? 39 : 64} />
          </Flex>
        </Flex>
        <Flex mt={mdDown ? 20 : 0}>
          <img src={'/images/down-phone.png'} width={mdDown ? '100%' : 500} />
        </Flex>
      </Flex>
    </Flex>
  );
}

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    download: {
      position: 'relative',
      overflowX: 'hidden',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'bottom center',
      backgroundSize: 'cover'
    },
    downAfter: {
      content: '',
      position: 'absolute',
      top: '-10%',
      left: '0',
      width: '100%',
      height: '50%',
      zIndex: 1,
      background: 'radial-gradient(50% 50% at 50% 50%, rgba(255, 170, 43, 0.2) 0%, rgba(255, 171, 46, 0) 100%)'
    },
    downForward: {
      content: '',
      position: 'absolute',
      left: '0',
      bottom: '-20%',
      width: '100%',
      height: '50%',
      zIndex: 1,
      background: 'radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.2) 0%, rgba(255, 171, 46, 0) 100%)'
    },
    text: {
      fontFamily: 'Roboto',
      fontWeight: 400
    }
  })
);

export default observer(DownloadPage);
