import React, { useState, useMemo } from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useSetState } from 'ahooks';
import { observer } from 'mobx-react';
import Flex from 'components/common/Flex';
import Text from 'components/common/Text';
import { Button, useMediaQuery, useTheme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';
import { AppTheme, Colors } from 'styles/theme';
import { AuthLogoSvg } from 'assets/icons';
import { accountLogin } from 'api/users';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import AlertMessage from 'components/common/AlertMessage';
import Dialog from '@material-ui/core/Dialog';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useHistory } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import useDepsContainer from 'hooks/useDepsContainer';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@material-ui/core/CircularProgress';

interface LoginFormProps {}

type BtnMessage = {
  open: boolean;
  message: string;
  severity: 'success' | 'error' | 'info' | 'warning';
};

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const LoginForm = (props: LoginFormProps) => {
  const styles = useStyles(props);
  const theme = useTheme();
  const mdDown = useMediaQuery(theme.breakpoints.down('md'));
  const history = useHistory();

  const { uiStore } = useDepsContainer();
  const { t, i18n } = useTranslation();
  const cachedT = useMemo(() => t, [t, uiStore.language]);
  let timeLoginRef = null as any;

  const [btnMessage, setBtnMessage] = useSetState<BtnMessage>({
    open: false,
    message: '',
    severity: 'info'
  });

  const [btnFinMessage, setBtnFinMessage] = useSetState<BtnMessage>({
    open: false,
    message: '',
    severity: 'info'
  });

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [showLogin, setShowLogin] = useState(true);
  const [open, setOpen] = useState(false);

  const formik = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    validationSchema: yup.object().shape({
      username: yup
        .string()
        .required(cachedT('usernameIsRequired'))
        .min(4, cachedT('usernameTooShort'))
        .max(20, cachedT('usernameTooLong'))
        .matches(/^(?=.*[A-Za-z])[A-Za-z0-9]{4,20}$/, cachedT('usernameIncorrectFormat')),
      password: yup
        .string()
        .required(cachedT('passwordIsRequired'))
        .min(6, cachedT('passwordTooShort'))
        .max(40, cachedT('password_too_long', { short: 3, long: 40 }))
        .matches(/^.{6,40}$/, cachedT('passwordIncorrectFormat'))
    }),
    onSubmit: async values => {
      if (loading) {
        return;
      }
      setLoading(true);
      clearTimeout(timeLoginRef);

      const { username, password } = values;
      // 在此处执行提交逻辑，例如调用 API 进行身份验证等
      await accountLogin({
        username,
        password,
        clientId: '63fd83a3bfb0172d558a50b9',
        platform: 4
      })
        .then(res => {
          const data = res?.data;

          if (data.code === 200) {
            // setBtnFinMessage({
            //   open: true,
            //   message: cachedT("successfulLoginMessage"),
            //   severity: "success",
            // });
            setBtnMessage({
              open: true,
              message: cachedT('successfulLoginMessage'),
              severity: 'success'
            });
            uiStore.isLogin = true;
            localStorage.setItem('token', data?.data?.token);
            if (document.referrer) {
              window.location.href = document.referrer;
            } else {
              timeLoginRef = setTimeout(() => history.push('/'), 1000);
            }
          } else {
            setBtnMessage({
              open: true,
              message: data?.message,
              severity: 'error'
            });
          }
        })
        .catch(() => {
          setBtnMessage({
            open: true,
            message: cachedT('serverExceptionMessage'),
            severity: 'error'
          });
        })
        .finally(() => setLoading(false));
    }
  });

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSnackbarClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setBtnMessage({
      open: false
    });
    clearTimeout(timeLoginRef);
  };

  const handleDialogClose = () => {
    setBtnFinMessage({
      open: false
    });
    history.push('/');
  };

  return (
    <>
      {showLogin ? (
        <Flex
          className={styles.loginForm}
          column
          pt={mdDown ? 6 : 10}
          pb={10}
          px={mdDown ? 5 : 10}
          mx={2}
          style={{
            width: mdDown ? '100%' : '490px',
            height: mdDown ? 'auto' : '575px',
            borderRadius: mdDown ? '16px' : '40px'
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Flex className={styles.surround} column center>
              <AuthLogoSvg />
            </Flex>
            <Flex className={styles.surround} column pt={6}>
              <Text className={styles.label} pb={1}>
                Username
              </Text>
              <FormControl>
                <OutlinedInput
                  id="username"
                  name="username"
                  placeholder={cachedT('placeholderUsername')}
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  error={formik.touched.username && Boolean(formik.errors.username)}
                />
                <FormHelperText style={{ color: Colors.inputErrorColor }}>
                  {formik.touched.username && formik.errors.username}
                </FormHelperText>
              </FormControl>
            </Flex>
            <Flex className={styles.surround} column pt={formik.touched.username ? 3 : 6}>
              <Text className={styles.label} pb={1}>
                Password
              </Text>
              <FormControl>
                <OutlinedInput
                  id="password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  placeholder={cachedT('placeholderPassword')}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={cachedT('togglePasswordVisibility')}
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  error={formik.touched.password && Boolean(formik.errors.password)}
                />
                <FormHelperText style={{ color: Colors.inputErrorColor }}>
                  {formik.touched.password && formik.errors.password}
                </FormHelperText>
              </FormControl>
            </Flex>
            <Flex className={styles.surround} column pt={6}>
              <Button
                className={styles.button}
                type="submit"
                variant="contained"
                color="primary"
                disabled={loading}
                style={{
                  boxShadow: 'none',
                  padding: '16px 0',
                  borderRadius: '80px',
                  fontSize: '18px'
                }}
              >
                {loading && <CircularProgress size={20} color={'inherit'} />}&nbsp;
                {cachedT('signIn')}
              </Button>
            </Flex>
            {/* Snackbar */}
            <AlertMessage
              open={btnMessage.open}
              message={btnMessage.message}
              severity={btnMessage.severity}
              onClose={handleSnackbarClose}
            />
            {/* Snackbar */}
            <Flex className={styles.surround} pt={6} color={'black'} display={'inline-block'} textAlign={'center'}>
              <Text display={'inline-block'}>{cachedT('noAccountYet')}</Text>&nbsp;
              <Flex
                component={'a'}
                onClick={() => {
                  //history.push('/download');
                  setShowLogin(false);
                }}
                color={'#1765CF'}
                display={'inline-block'}
                cursorPointer
              >
                {cachedT('downloadApp')}
              </Flex>
              &nbsp;
              <Text display={'inline-block'}>{cachedT('toRegister')}</Text>
            </Flex>
          </form>

          {/* Success */}
          <Dialog fullScreen={mdDown} open={btnFinMessage.open}>
            <Flex pt={4} color={'#00BA00'} center>
              <CheckCircleIcon
                style={{
                  width: 60,
                  height: 60
                }}
              />
            </Flex>
            <Flex px={10} pb={4} column>
              <Flex mb={10} mt={5}>
                {btnFinMessage.message}
              </Flex>
              <Flex center>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    boxShadow: 'none',
                    padding: '4px 60px',
                    borderRadius: '80px',
                    fontSize: '14px',
                    background: '#3B3C45'
                  }}
                  onClick={handleDialogClose}
                >
                  {cachedT('btnConfirm')}
                </Button>
              </Flex>
            </Flex>
          </Dialog>
          {/* Snackbar */}
        </Flex>
      ) : (
        <Flex
          className={styles.loginForm}
          column
          pt={mdDown ? 6 : 6}
          pb={10}
          px={mdDown ? 5 : 6}
          mx={2}
          style={{
            width: mdDown ? '100%' : '490px',
            height: mdDown ? 'auto' : '575px',
            borderRadius: mdDown ? '16px' : '40px'
          }}
        >
          <Flex className={styles.downloadHeader}>
            <em
              onClick={() => {
                setShowLogin(true);
              }}
            ></em>
            {cachedT('downloadApp')}
          </Flex>
          <div className={styles.title}>{cachedT('InviteScanQRCode')}</div>
          <div className={styles.qrCode}>
            <img src="/images/qrcode.png" alt="" />
          </div>
          <p className={styles.copyText}>{cachedT('InviteCopyLink')}</p>
          <div className={styles.link}>
            <span className={styles.text}>{location.origin}</span>
            <CopyToClipboard
              text={location.origin}
              onCopy={() => {
                setOpen(true);
              }}
            >
              <span className={styles.icon}></span>
            </CopyToClipboard>
          </div>
          <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={() => {
              setOpen(false);
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          >
            <div>
              <Alert
                onClose={() => {
                  setOpen(false);
                }}
                variant="outlined"
                severity="success"
                style={{ background: '#FFF' }}
              >
                {cachedT('InviteCopySuccess')}
              </Alert>
            </div>
          </Snackbar>
        </Flex>
      )}
    </>
  );
};

const useStyles = makeStyles((theme: AppTheme) =>
  createStyles({
    loginForm: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      background: 'white'
    },
    surround: {
      width: '100%'
    },
    label: {
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '24px',
      color: '#232429'
    },
    button: {
      background: 'linear-gradient(90deg, #FF5F6D 0%, #FFC371 100%)'
    },
    downloadHeader: {
      color: '#232429',
      fontFamily: 'Inter',
      fontSize: '24px',
      fontWeight: '700',
      lineHeight: '28px',
      letterSpacing: '-0.3px',
      textAlign: 'left',
      width: '100%',
      marginBottom: '16px',

      '& em': {
        display: 'inline-block',
        width: '28px',
        height: '28px',
        background: 'url(/images/icons/left-arrow.svg) center no-repeat',
        cursor: 'pointer',
        marginRight: '5px'
      }
    },
    title: {
      color: '#18181B',
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '18px',
      fontWeight: '700',
      lineHeight: '28px',
      marginBottom: '16px'
    },
    qrCode: {
      width: '316px',
      height: '316px',
      margin: '0 auto',

      '& img': {
        width: '100%',
        height: '100%'
      }
    },
    copyText: {
      fontFamily: 'Inter',
      color: '#5F606D',
      textAlign: 'center',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
      margin: '16px auto'
    },
    link: {
      display: 'flex',
      padding: '8px',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '8px',
      background: '#F3F4FC',
      marginBottom: '16px',
      width: '100%'
    },
    text: {
      width: '100%',
      fontFamily: 'Inter',
      color: '#18181B',
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
      marginRight: '8px'
    },
    icon: {
      width: '24px',
      height: '24px',
      background: 'url(/images/icons/copy.svg) center no-repeat',
      cursor: 'pointer'
    }
  })
);

export default observer(LoginForm);
