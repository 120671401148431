import { isMobileDevice } from 'utils/helper'
import { useDevice } from "./useDevice"
import { isWindows } from "react-device-detect";

export function useAppLink() {

    const { isMobile, isMac, isWindows, isAndroid, isIOS } = useDevice();

    function goCpd() {
        window.open('https://cpd.pumpkin.xyz')
    }

    function goAppStore() {
        window.open('https://apps.apple.com/us/app/pumpkin-safe-social/id1672017600')
    }

    function goGooglePlay() {
        window.open('https://play.google.com/store/apps/details?id=com.pumpkin.date')
    }

    function goAPK() {
        window.open('https://download.pumpkin.xyz/apk/pumpkin.apk')
    }

    // 检测是否支持 Google Play
    function checkGooglePlay() {
        try {
            // 尝试创建market协议的链接
            const testIntent = document.createElement('a');
            testIntent.href = 'market://details?id=com.android.vending';
            // 如果没有抛出异常，说明支持market协议
            return true;
        } catch (e) {
            return false;
        }
    };
    
    function toAppStore() {
        isMobile && isIOS ? goCpd() : goAppStore()
    }

    function toGooglePlay() {
        isMobile && isAndroid ? goCpd() : goGooglePlay()
    }

    function toCpdDownload() {
        // 移动端
        if (isMobile) {
            // 安卓手机且支持 Google Play
            if (isAndroid && checkGooglePlay()) {
                goCpd();
                return;
            }
            // 安卓手机且不支持 Google Play
            if (isAndroid && !checkGooglePlay()) {
                goAPK();
                return;
            }
            // 默认直接利用 applink 做跳转
            goCpd();
            return;
        }

        // 桌面端
        if (isMac) goAppStore();
        if (isWindows) goGooglePlay();
    }

    return {
        goCpd,
        goAppStore,
        goGooglePlay,
        goAPK,

        toAppStore,
        toGooglePlay,
        toCpdDownload
    }
}