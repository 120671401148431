import {
  isAndroid,
  isIOS,
  isMacOs,
  isWindows,
  isMobileOnly,
  isTablet,
  isMobile,
  isDesktop,
} from 'react-device-detect'

export const useDevice = (): {
  isAndroid: boolean
  isIOS: boolean
  isMacOs: boolean
  isTablet: boolean
  isMobileOnly: boolean
  isMobile: boolean
  isDesktop: boolean
  isAndroidPhone: boolean
  isAndroidTablet: boolean
  isIOSPhone: boolean
  isIOSTablet: boolean
  isMac: boolean
  isWindows: boolean
} => {
  return {
    // 安卓系统(安卓手机, 安卓平板...)
    isAndroid,

    // iOS系统(iPhone, iPad, iPod...)
    isIOS,

    // macOS系统(Mac, MacBook, MacBook Pro...)
    isMacOs,

    // 手机
    isMobileOnly,

    // 平板(iPad, 安卓平板...)
    isTablet,

    // 移动设备(手机, 平板...)
    isMobile,

    // 桌面端浏览器
    isDesktop,

    // 是否是安卓手机
    isAndroidPhone: isAndroid && isMobileOnly,

    // 是否是安卓平板
    isAndroidTablet: isAndroid && isTablet,

    // 是否是iOS手机
    isIOSPhone: isIOS && isMobileOnly,

    // 是否是iOS平板
    isIOSTablet: isIOS && isTablet,

    // 是否是Mac设备
    isMac: isMacOs && isDesktop,

    // 是否是Windows设备
    isWindows: isWindows && isDesktop,
  }
}
